<template>
  <v-row align="start" class="row--35">
    <v-col lg="6" md="6" sm="12" cols="12" order="1" order-md="1">
      <div class="section-title text-left mb--50 mb_sm--30 mb_md--30">
        <h2 class="heading-title">Kontakt aufnehmen.</h2>
        <p class="description">
          Fülle das folgende Formular aus und wir melden uns bei dir!
        </p>
      </div>
      <div class="form-wrapper">
        <form @submit.prevent>
          <label>
            <input type="text" v-model="name" placeholder="Dein Name *"/>
          </label>

          <label>
            <input type="text" v-model="email" placeholder="Deine Email *"/>
          </label>

          <label>
            <textarea v-model="message" placeholder="Deine Anfrage *"></textarea>
          </label>

          <button
              class="rn-button-style--2 btn_solid"
              type="submit"
              value="submit"
              @click="sendContactRequest"
          >
            Absenden
          </button>
        </form>
        <v-alert type="success" class="my-4" v-model="alert" dismissible>
          Anfrage wurde erfolgreich gesendet. Vielen Dank!
        </v-alert>
        <v-alert type="error" class="my-4" v-model="error" dismissible>
          Anfrage konnte nicht gesendet werden. Versuche es später noch einmal oder melde dich direkt über hello@goelditechnology.ch
        </v-alert>
        <v-alert type="warning" class="my-4" v-model="warning" dismissible>
          Bitte fülle alles Felder aus, bevor du die Anfrage absendest.
        </v-alert>
      </div>
    </v-col>
    <v-col lg="6" md="6" sm="12" cols="12" order="2" order-md="2" class="d-md-flex">
        <iframe class="mt-4 mt-md-0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2699.2533832487993!2d9.368956676951468!3d47.426502400396124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479b1e49bb0b8905%3A0x342d7c76571baaa0!2sZwinglistrasse%2010%2C%209000%20St.%20Gallen!5e0!3m2!1sde!2sch!4v1685951079348!5m2!1sde!2sch" width="100%" height="450" style="border:0; allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

export default {


  data() {
    return {
      name: "",
      email: "",
      message: "",
      alert: false,
      error: false,
      warning: false
    };
  },
  methods: {
    sendContactRequest() {
      this.error = false
      this.warning = false
      if (this.email == '' || this.name == '' || this.message == ''){
        this.warning = true
      }else{
        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          data: {name: this.name, email: this.email, message: this.message},
          url: 'https://service.goelditechnology.ch/api/chats',
        };

        axios(options)
            .then((res) => {
              console.log(res)
              if(res.status=== 204){
                this.alert = true
                this.name = ''
                this.email = ''
                this.message = ''
              }else{
                this.error = true
              }
            })
            .catch((err) => {
              this.error = true
            })
      }

    }
  }
};
</script>
